import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Table from 'components/Table/Table.js';
import CategoryPicker from 'views/Dashboard/Overview/RunningPairs/CategoryPicker';
import GunbotStorage from 'assets/js/gunbot.storage';
import TabSettingPanel from 'components/Setting/TradingTerminalTabSettingPanel';
import ServerSideTable from 'components/Table/ServerSideTable';
import Api from 'api/index';
import { useState } from 'react';
import { selectBalanceInfo, selectPairItem } from 'redux/selectors';
import { getDateTimeString } from 'assets/js/common';
import { AccountBalanceWallet, ExpandMore } from '@material-ui/icons';
import scientificToDecimal from 'scientific-to-decimal';

import { useSnackbar } from 'notistack';

const getColor = data => {
  if (data)
  return data.toUpperCase() === 'BUY' ? 'orders.buy' : 'orders.sell';
  else return 'orders.sell'
};

export default function TradingTerminal(props) {
  let { pair, exchange, isCoinM, hasLong, hasShort, marketType = '', currentQty = 0 } = props;
  const selectedBalanceInfo = useSelector(selectBalanceInfo(exchange, pair));
  const key = `${exchange}/${pair}`;
  const openOrders = useSelector(selectPairItem(key, 'openOrders'));
  const orderBook = useSelector(selectPairItem(key, 'orderbook'));
  const isMargin = !marketType.includes('spot')
  const Ask = useSelector(selectPairItem(key, 'Ask'));
  const Bid = useSelector(selectPairItem(key, 'Bid'));
  const showSinglePairInterface = useSelector(state => state.settings.corememData.licSinglePairEdition);

  const [base, quote = 'Loading...'] = (pair || '-').split('-');
  const { baseBalance = 0, quoteBalance = 0 } = selectedBalanceInfo || {};
  const [pendingCancelOrders, setPendingCancelOrders] = useState([]);
  const [place, setPlace] = useState('limit');
  const [buyAmount, setBuyAmount] = useState('');
  const [buyPrice, setBuyPrice] = useState(Ask);
  const [closeBuy, setCloseBuy] = useState(false);
  const [sellAmount, setSellAmount] = useState('');
  const [sellPrice, setSellPrice] = useState(Bid);
  const [closeSell, setCloseSell] = useState(false);
  const [orderbookBackground, setOrderbookBackground] = useState({
    ask: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    bid: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [tradingHistoryHeads, setTradingHistoryHeads] = useState([]);

  const [spread, setSpread] = useState(0);
  const [spreadPct, setSpreadPct] = useState('');
  const [currentOpenOrders, setCurrentOpenOrders] = useState([]);
  const [currentPair, setCurrentPair] = useState('');
  const [data, setData] = useState({});
  const [showLong, setShowLong] = useState(true);
  const [showShort, setShowShort] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendNotification = function (text, variant) {
    enqueueSnackbar(text, {
      variant: variant || 'success',
      preventDuplicate: true,
      style: { whiteSpace: 'pre-line' },
    });
  };

  const resetForm = function () {
    setBuyAmount('');
    setBuyPrice(Ask);
    setCloseBuy(false);
    setSellAmount('');
    setSellPrice(Bid);
    setCloseSell(false);
  };

  const resetAmount = function () {
    setBuyAmount('');
    setSellAmount('');
  };

  const handleCloseBuyCheckbox = function (newValue) {
    setCloseBuy(newValue)
    resetAmount()
  }

  const handleCloseSellCheckbox = function (newValue) {
    setCloseSell(newValue)
    resetAmount()
  }

  // expand value for orderBook of the overview
  const [orderBookExpand, setOrderBookExpand] = useState(true);
  const handleBuyPercent = useCallback(
    percent => () => {
      if (isCoinM) {
        const amt = Math.abs(quoteBalance * percent) / 100;
        setBuyAmount(amt);
      }
      else if (!closeBuy && place === 'limit') {
        let price = buyPrice || Ask;
        let baseValue = ((baseBalance / price * percent) / 100);
        let bybitAmount = (baseBalance * percent) / 100;
        setBuyPrice(price);
        if (exchange == 'bybit') setBuyAmount((bybitAmount).toFixed(8));
        else setBuyAmount((bybitAmount / price).toFixed(8));
      }
      else if (!closeBuy && place === 'market') {
        let price = buyPrice || Ask;
        let baseValue = ((baseBalance / price * percent) / 100);
        let bybitAmount = (baseBalance * percent) / 100;
        //setBuyAmount((baseValue / 1.005).toFixed(8));
        /// attempt to fix zy0n report
        if (exchange == 'bybit') setBuyAmount((bybitAmount).toFixed(8));
        else setBuyAmount((bybitAmount / price).toFixed(8));
        setBuyPrice(price);
      }
      else if (closeBuy) {
        let baseValue = (currentQty * percent) / 100;
        let price = buyPrice || Ask;
        setBuyPrice(price);
        setBuyAmount(baseValue.toFixed(8));
      }
      else {
        let baseValue = (quoteBalance * percent) / 100;
        let price = buyPrice || Ask;
        setBuyPrice(price);
        setBuyAmount(baseValue.toFixed(8));
      }
    },
    [setBuyAmount, baseBalance, Ask, buyPrice, closeBuy, place],
  );

  /*const handleSellPercent = useCallback(
    percent => () => {
      if (!closeSell) {
        let baseValue = (baseBalance * percent) / 100;
        let price = buyPrice || Bid;
        setSellPrice(price);
        setSellAmount((baseValue / 1.005 / price).toFixed(8));
      } else {
        let baseValue = (quoteBalance * percent) / 100;
        let price = sellPrice || Ask;
        setSellPrice(price);
        setSellAmount(baseValue.toFixed(8));
      }
    },
    [setSellAmount, baseBalance, quoteBalance, Bid, sellPrice, closeSell]
  );*/

  const handleSellPercent = useCallback(
    percent => () => {
      if (isCoinM) {
        const amt = Math.abs(quoteBalance * percent) / 100;
        setSellAmount(amt);
      }
      else if (closeSell) {
        let baseValue = (Math.abs(currentQty) * percent) / 100;
        let price = sellPrice || Bid;
        setSellPrice(price);
        setSellAmount(baseValue.toFixed(8));
      }
      else if (exchange == 'bitget' && !marketType.includes('spot')) {
        let baseValue = (Math.abs(baseBalance) * percent) / 100;
        let price = sellPrice || Bid;
        setSellPrice(price);
        setSellAmount(baseValue.toFixed(8));
      }
      else if (exchange !== 'binanceFutures' && exchange !== 'futures_gunthy') {
        let price = sellPrice || Bid;
        setSellPrice(price);
        if (exchange == 'bybit' && !closeSell && !marketType.includes('spot')) {
          setSellAmount(((baseBalance * percent) / 100).toFixed(8));
        } else {
          setSellAmount(((quoteBalance * percent) / 100).toFixed(8));
        }
      }
      else {
        if (!closeSell) {
          let price = sellPrice || Bid;
          let baseValues = (baseBalance * percent) / 100;
          setSellAmount((baseValues / price).toFixed(8));
          setSellPrice(price);
        } else {
          setSellAmount(((quoteBalance * percent) / 100).toFixed(8));
          let price = sellPrice || Bid;
          setSellPrice(price);
        }
      }
    },
    [setSellAmount, baseBalance, quoteBalance, Bid, sellPrice, closeSell],
  );

  const handleCloseOrder = useCallback(() => {
    function request() {
      let closeSide = quoteBalance < 0 ? 'sell' : 'buy';
      let requestData = {
        amt: parseFloat(buyAmount),
        price: buyPrice,
        exch: exchange,
        pair: pair,
      };
      if (exchange == 'bybit' || exchange === 'bitget') {
        if (place === 'limit' && closeSide === 'buy') {
          requestData.amt = Math.abs(buyAmount);
          requestData.price = buyPrice;
          sendNotification('CLOSE order sent side LONG', 'info');
          return Api.placeCloseOrder(requestData);
        } else if (place === 'limit' && closeSide === 'sell') {
          requestData.amt = Math.abs(sellAmount) * -1;
          requestData.price = sellPrice;
          sendNotification('CLOSE order sent', 'info');
          return Api.placeCloseOrder(requestData);
        } else if (place !== 'limit' && closeSide === 'sell') {
          requestData.amt = Math.abs(sellAmount) * -1;
          sendNotification('CLOSE order sent', 'info');
          return Api.placeCloseMarketOrder(requestData);
        } else if (place !== 'limit' && closeSide === 'buy') {
          requestData.amt = Math.abs(buyAmount);
          sendNotification('CLOSE order sent', 'info');
          return Api.placeCloseMarketOrder(requestData);
        }
      } else if (exchange !== 'binanceFutures' && exchange !== 'futures_gunthy') {
        if (place === 'limit') {
          sendNotification('CLOSE order sent', 'info');
          return Api.placeCloseOrder(requestData);
        } else {
          sendNotification('CLOSE order sent', 'info');
          return Api.placeCloseMarketOrder(requestData);
        }
      } else {
        if (place === 'limit' && closeSide === 'buy') {
          requestData.amt = buyAmount;
          sendNotification('CLOSE order sent', 'info');
          return Api.placeSellOrder(requestData);
        } else if (place === 'limit' && closeSide === 'sell') {
          requestData.amt = Math.abs(sellAmount);
          sendNotification('CLOSE order sent', 'info');
          return Api.placeBuyOrder(requestData);
        } else if (place !== 'limit' && closeSide === 'sell') {
          requestData.amt = Math.abs(sellAmount);
          sendNotification('CLOSE order sent', 'info');
          return Api.placeMarketBuyOrder(requestData);
        } else if (place !== 'limit' && closeSide === 'buy') {
          requestData.amt = buyAmount;
          sendNotification('CLOSE order sent', 'info');
          return Api.placeMarketSellOrder(requestData);
        }
      }
    }

    request().then(response => {
      if (response.data === 'false') {
        console.error('Something went wrong.');
      }
    });
  }, [quoteBalance, place, buyAmount, buyPrice, sellAmount, sellPrice, exchange, pair, closeBuy, closeSell]);

  const handleBuyOrder = useCallback(() => {
    if (buyAmount === '') {
      return;
    }
    let amount = parseFloat(buyAmount);

    if (exchange == 'nashio') amount = parseFloat(buyAmount) * parseFloat(Ask);
    const requestData = {
      amt: isCoinM
        ? amount
        : place === 'limit' && exchange != 'bybit'
          ? amount
          : exchange == 'bybit' && place == 'market'
            ? amount
            : amount,
      price: place === 'limit' ? parseFloat(buyPrice) : Bid,
      exch: exchange,
      pair: pair,
    };

    if (exchange === 'bitget' && marketType === 'swap' && place === 'market') {
      requestData.amt = parseFloat(buyAmount) / parseFloat(Ask)
    }
    /*amt
: 
0.0003425032880315651
exch
: 
"bybit"
pair
: 
"USDT-BTC-LONG"
price
: 
29126.9*/
    if (closeBuy) {
      handleCloseOrder();
    } else {
      if (place === 'limit') {
        sendNotification('BUY order sent', 'success');
        return Api.placeBuyOrder(requestData);
      } else {
        sendNotification('BUY order sent', 'succes');
        return Api.placeMarketBuyOrder(requestData);
      }
    }
    resetForm();
  }, [buyAmount, buyPrice, exchange, pair, place, handleCloseOrder, closeBuy, marketType]);

  const handleSellOrder = useCallback(() => {
    if (sellAmount === '') {
      return;
    }
    let requestData = {
      amt: parseFloat(sellAmount),
      price: place === 'limit' ? parseFloat(sellPrice) : Ask,
      exch: exchange,
      pair: pair,
    };
    if (closeSell) {
      handleCloseOrder();
    } else {
      if (place === 'limit') {
        //if (exchange == "bybit")
        //requestData.amt = requestData.amt * requestData.price
        sendNotification('SELL order sent', 'error');
        return Api.placeSellOrder(requestData);
      } else {
        sendNotification('SELL order sent', 'error');
        return Api.placeMarketSellOrder(requestData);
      }
    }
    resetForm();
  }, [sellAmount, sellPrice, exchange, pair, handleCloseOrder, place, closeSell]);

  const cancelOrder = useCallback(
    order => () => {
      const data = {
        price: order.rate,
        id: order.id,
        exch: exchange,
        pair: pair,
        type: order.type,
      };

      return Api.cancelOrder(data)
        .then(() => {
          sendNotification('Cancel order sent', 'info');
          setPendingCancelOrders([...pendingCancelOrders, order.id]);
          setTimeout(() => {
            setPendingCancelOrders(pendingCancelOrders.filter(item => item !== order.id));
          }, 10 * 1000);
        })
        .catch(error => {
          console.error('Error: ', error);
        });
    },
    [exchange, pair, pendingCancelOrders],
  );

  const handleHeadsTradingHistory = useCallback(data => {
    GunbotStorage.set('TRADING_HISTORY', data);
    setTradingHistoryHeads(data);
  }, []);

  useEffect(() => {
    let heads = [];
    let defaultHeads = [labelsTradingHistory[0], labelsTradingHistory[2], labelsTradingHistory[3]];
    heads = GunbotStorage.get('TRADING_HISTORY', defaultHeads);
    setTradingHistoryHeads(heads);
  }, []);

  useEffect(() => {
    const temp = [];
    if (openOrders) {
      openOrders.forEach(item => {
        const rate = (
          <Box color={`${getColor(item.type)}`} component={'span'}>
            {item.rate}
          </Box>
        );
        const time = getDateTimeString(item.time);
        const control = (
          <IconButton color="inherit" aria-label="cancel order" onClick={cancelOrder(item)}>
            {pendingCancelOrders.indexOf(item.id) >= 0 ? <HourglassEmptyIcon /> : <HighlightOffIcon />}
          </IconButton>
        );
        temp.push([rate, item.amount, time, control]);
      });
    }
    setCurrentOpenOrders(temp);
  }, [exchange, pair, openOrders, cancelOrder, pendingCancelOrders]);

  useEffect(() => {
    // reset form values after changing pair
    let pairChanged = false;
    if (currentPair !== pair) {
      setCurrentPair(pair);
      pairChanged = true;
    }

    if (!_.isNil(pair) && pairChanged) {
      if (pair.endsWith('-SHORT')) {
        setShowLong(false);
      } else {
        setShowLong(true);
      }
      if (pair.endsWith('-LONG')) {
        setShowShort(false);
      } else {
        setShowShort(true);
      }
      resetForm();
    }
  }, [props]);

  useEffect(() => {
    const isAbleToBuy = buyAmount && parseFloat(buyAmount) > 0;
    const isAbleToSell = sellAmount && parseFloat(sellAmount) > 0;
    let displaySymbolBuy = quote;
    let displaySymbolSell = quote;
    if (exchange === 'bybit') {
      if (!closeBuy && isMargin) {
        displaySymbolBuy = base;
      }
      if (!closeSell && isMargin) {
        displaySymbolSell = base;
      }
    } else if (isCoinM) {
      displaySymbolBuy = 'Contracts';
      displaySymbolSell = 'Contracts';
    } else if (place === 'market' && !closeBuy) {
      displaySymbolBuy = quote;
      displaySymbolSell = quote;
    }

    const temp = {};
    ['limit', 'market'].forEach(tabName => {
      temp[tabName] = [
        {
          type: 'component',
          component: (
            <Box display="flex" flexWrap="wrap" mt={-4} mb={-2} mx={{ xs: -3 }}>
              <>
                {showLong && (
                  <Box width={{ xs: '100%' }} pr={{ xs: 0 }} mb={2}>
                    {isCoinM ? (
                      <Box display="flex" alignItems="center" mb={2}>
                        <AccountBalanceWallet />{' '}
                        <span>
                          {quote}: {baseBalance}
                        </span>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" mb={2}>
                        <AccountBalanceWallet />{' '}
                        <span>
                          {base}: {baseBalance}
                        </span>
                      </Box>
                    )}
                    {place === 'limit' && (
                      <Box mb={2}>
                        <FormControl variant="outlined" size={'small'} fullWidth>
                          <InputLabel htmlFor="buy-price">Price</InputLabel>
                          <OutlinedInput
                            id="buy-price"
                            type={'number'}
                            value={buyPrice || ''}
                            onChange={e => {
                              setBuyPrice(e.target.value);
                            }}
                            endAdornment={<InputAdornment position="end">{base}</InputAdornment>}
                            startAdornment={<InputAdornment position="start">Price:</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'price',
                            }}
                            labelWidth={40}
                          />
                        </FormControl>
                      </Box>
                    )}
                    <Box mb={1}>
                      <FormControl variant="outlined" size={'small'} fullWidth>
                        <InputLabel htmlFor="buy-amount">Amount</InputLabel>
                        <OutlinedInput
                          id="buy-amount"
                          type="number"
                          value={buyAmount}
                          onChange={e => {
                            setBuyAmount(e.target.value);
                          }}
                          endAdornment={<InputAdornment position="end">{displaySymbolBuy}</InputAdornment>}
                          startAdornment={<InputAdornment position="start">Amount:</InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          labelWidth={60}
                        />
                      </FormControl>
                    </Box>
                    {isCoinM && !hasShort ? (
                      <Box mb={4} display="flex" alignItems="center" />
                    ) : (
                      <>
                        <span style={{ minWidth: '100%', textAlign: 'left', paddingBottom: '8px', paddingLeft: '2px', display: 'inline-block', color: '#e4e4e4' }}>
                          {
                            closeBuy ?
                              'Set order amount as % of position' :
                              isCoinM ?
                                'Set order amount as % of coin balance' :
                                'Set order amount as % of base balance'
                          }
                        </span>
                        <Box mb={2} display="flex" alignItems="center">
                          <Box component={'span'} ml={'auto'} display={isMargin ? '' : 'none'}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={closeBuy}
                                  onChange={e => handleCloseBuyCheckbox(e.target.checked)}
                                  name="checkedB"
                                  color="secondary"
                                />
                              }
                              label="Close"
                            />
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleBuyPercent(25)}
                          >
                            25%
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleBuyPercent(50)}
                          >
                            50%
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleBuyPercent(75)}
                          >
                            75%
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleBuyPercent(100)}
                          >
                            100%
                          </Box>

                        </Box>
                      </>
                    )}
                    <Box>
                      {!closeBuy && !isAbleToBuy ? (
                        <Button
                          color={closeBuy ? 'primary' : `secondary`}
                          variant={'contained'}
                          disabled={!closeBuy && !isAbleToBuy}
                          fullWidth
                          onClick={handleBuyOrder}
                        >
                          {(closeBuy ? 'Close ' : '') + (isMargin ? 'Long' : 'Buy')}
                        </Button>
                      ) : closeBuy ? (
                        <Button
                          color={closeBuy ? 'primary' : `secondary`}
                          variant={'contained'}
                          disabled={!closeBuy && !isAbleToBuy}
                          fullWidth
                          onClick={handleBuyOrder}
                        >
                          {(closeBuy ? 'Close ' : '') + (isMargin ? 'Long' : 'Buy')}
                        </Button>
                      ) :
                        (
                          <Button
                            style={{
                              background: '#1B5E20',
                              color: '#fff',
                              '&:hover': {
                                background: '#174F1B',
                              },
                            }}
                            variant={'contained'}
                            disabled={!closeBuy && !isAbleToBuy}
                            fullWidth
                            onClick={handleBuyOrder}
                          >
                            {(closeBuy ? 'Close ' : '') + (isMargin ? 'Long' : 'Buy')}
                          </Button>
                        )}
                    </Box>
                  </Box>
                )}
              </>
              <Box width={{ xs: '100%' }} height={{ xs: '1px' }} mb={{ xs: 2 }} bgcolor={grey[800]} />
              <>
                {showShort && (
                  <Box width={{ xs: '100%' }} pl={{ xs: 0 }} mb={2}>
                    {isCoinM ? (
                      <Box display="flex" alignItems="center" mb={2}>
                        <AccountBalanceWallet /> Contracts: {quoteBalance}
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" mb={2}>
                        <AccountBalanceWallet /> {quote}: {quoteBalance}
                      </Box>
                    )}
                    {place === 'limit' && (
                      <Box mb={2}>
                        <FormControl variant="outlined" size={'small'} fullWidth>
                          <InputLabel htmlFor="sell-price">Price</InputLabel>
                          <OutlinedInput
                            id="sell-price"
                            type="number"
                            placeholder={'0.00'}
                            value={sellPrice || ''}
                            autoFocus={true}
                            onChange={e => {
                              setSellPrice(e.target.value);
                            }}
                            endAdornment={<InputAdornment position="end">{base}</InputAdornment>}
                            startAdornment={<InputAdornment position="start">Price:</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight',
                            }}
                            labelWidth={40}
                          />
                        </FormControl>
                      </Box>
                    )}
                    <Box mb={1}>
                      <FormControl variant="outlined" size={'small'} fullWidth>
                        <InputLabel htmlFor="sell-amount">Amount</InputLabel>
                        <OutlinedInput
                          id="sell-amount"
                          type="number"
                          placeholder={'0.00'}
                          value={sellAmount}
                          onChange={e => {
                            setSellAmount(e.target.value);
                          }}
                          endAdornment={<InputAdornment position="end">{displaySymbolSell}</InputAdornment>}
                          startAdornment={<InputAdornment position="start">Amount:</InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          labelWidth={60}
                        />
                      </FormControl>
                    </Box>
                    {isCoinM && !hasLong ? (
                      <Box mb={4} display="flex" alignItems="center" />
                    ) : (
                      <>
                        <span style={{ minWidth: '100%', textAlign: 'left', paddingBottom: '8px', paddingLeft: '2px', display: 'inline-block', color: '#e4e4e4' }}>
                          {
                            closeSell ?
                              'Set order amount as % of position' :
                              isCoinM ?
                                'Set order amount as % of coin balance' :
                                exchange == 'bybit' ?
                                  'Set order amount as % of base balance' :
                                  'Set order amount as % of quote balance'
                          }
                        </span>
                        <Box mb={2} display="flex" alignItems="center">
                          <Box component={'span'} ml={'auto'} display={isMargin ? '' : 'none'}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={closeSell}
                                  onChange={e => handleCloseSellCheckbox(e.target.checked)}
                                  name="checkedB"
                                  color="secondary"
                                />
                              }
                              label="Close"
                            />
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleSellPercent(25)}
                          >
                            25%
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleSellPercent(50)}
                          >
                            50%
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleSellPercent(75)}
                          >
                            75%
                          </Box>
                          <Box
                            component={'span'}
                            px={{ xs: 0.5, sm: 1, md: 2 }}
                            style={{ cursor: 'pointer' }}
                            py={0.5}
                            mr={1}
                            bgcolor={grey[800]}
                            onClick={handleSellPercent(100)}
                          >
                            100%
                          </Box>

                        </Box>
                      </>
                    )}
                    <Box>
                      <Button
                        color={closeSell ? 'primary' : 'secondary'}
                        variant={'contained'}
                        disabled={!closeSell && !isAbleToSell}
                        fullWidth
                        onClick={handleSellOrder}
                      >
                        {(closeSell ? 'Close ' : '') + (isMargin ? 'Short' : 'Sell')}
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            </Box>
          ),
        },
      ];
    });
    setData(temp);
  }, [
    base,
    baseBalance,
    quote,
    quoteBalance,
    buyAmount,
    sellAmount,
    buyPrice,
    sellPrice,
    place,
    closeSell,
    closeBuy,
    handleBuyOrder,
    handleBuyPercent,
    handleSellOrder,
    handleSellPercent,
    isMargin,
  ]);

  useEffect(() => {
    const orderbook = orderBook || {
      ask: [],
      bid: [],
    };
    let spread = 0;
    if (orderbook.ask.length > 0 && orderbook.bid.length > 0) {
      spread = scientificToDecimal(parseFloat((orderbook.ask[0][0] - orderbook.bid[0][0]).toString()).toFixed(8));
    }

    const askVolume = orderbook.ask.map(item => {
      return item[1];
    });
    const bidVolume = orderbook.bid.map(item => {
      return item[1];
    });
    const highestVolume = Math.max(...askVolume, ...bidVolume);
    let backgroundData = {
      ask: [],
      bid: [],
    };
    if (askVolume.length > 0 && bidVolume.length > 0) {
      askVolume.forEach(item => {
        backgroundData.ask.push((item / highestVolume) * 100);
      });

      bidVolume.forEach(item => {
        backgroundData.bid.push((item / highestVolume) * 100);
      });
      setOrderbookBackground(backgroundData);
    }

    setSpread(spread);
    if (spread !== 0) {
      setSpreadPct(((parseFloat(spread) / orderbook.bid[0][0]) * 100).toFixed(2).toString());
    }
  }, [orderBook]);

  useEffect(() => {
    let price = buyPrice;
    if (place === 'market') {
      price = Ask;
    }
    /*if (price * parseFloat(buyAmount) * 1.005 > baseBalance) {
      let amount = baseBalance / price / 1.005;
      setBuyAmount(amount.toFixed(8));
    }*/
  }, [buyPrice, buyAmount, place, Ask, baseBalance]);

  /*useEffect(() => {
    if (sellAmount > quoteBalance && exchange != 'binanceFutures') {
      setSellAmount(quoteBalance.toString());
    }
  }, [sellAmount, quoteBalance, setSellAmount]);*/

  return (
    <Fragment>
      {!showSinglePairInterface ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Box fontSize={'16px'} fontWeight={500} color={grey[200]} whiteSpace={'nowrap'}>
              Trading Terminal
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0px' }}>
            <Box mt={1}>
              <TabSettingPanel
                withoutCardHeader={true}
                data={data}
                onTabChanged={tabName => {
                  setPlace(tabName.toLowerCase());
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : null}

      <Accordion expanded={orderBookExpand} onChange={() => setOrderBookExpand(!orderBookExpand)}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Box fontSize={'16px'} fontWeight={500} color={grey[200]} whiteSpace={'nowrap'}>
            Order Book
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Box textAlign={'center'} width={'100%'} color={grey[200]}>
              <h5 style={{ marginTop: '-20px', marginBottom: '0px' }}>
                <small
                  style={{
                    fontSize: 'small',
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    lineHeight: 1.5,
                    fontWeight: '400',
                    color: '#AAAAAA',
                  }}
                >
                  Spread
                </small>
                <br />
                <span style={{ color: grey[200], fontWeight: '600', fontSize: 'larger' }}>
                  ${spread} ({spreadPct}%)
                </span>
              </h5>
            </Box>
            <Box display="flex" px={1} mt={2} width={'100%'} minHeight={'100px'}>
              <Box width={'calc(50% - 1px)'} display="flex" flexWrap="wrap">
                <Box width={'100%'} textAlign="left" fontWeight={600} mb={1} color={'orders.buy'}>
                  bid
                </Box>
                {orderBook &&
                  orderbookBackground.bid &&
                  orderBook.bid &&
                  orderBook.bid.map((bid, key) => {
                    return (
                      <Fragment key={key}>
                        <Box
                          textAlign="left"
                          width={'50%'}
                          color={'orders.buy'}
                          pb={1}
                          style={{ fontSize: 'small', paddingTop: '3px', paddingBottom: '3px' }}
                          onClick={() => setBuyPrice(scientificToDecimal(bid[0]))}
                        >
                          {scientificToDecimal(bid[0])}
                        </Box>
                        <Box
                          textAlign="right"
                          width={'50%'}
                          pb={1}
                          color={grey[200]}
                          style={{
                            background: `linear-gradient(to left, #1A3D00 ${orderbookBackground.bid[key]}%, transparent 15%)`,
                            fontSize: 'small',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            paddingRight: '3px',
                          }}
                          onClick={() => setBuyPrice(scientificToDecimal(bid[0]))}
                        >
                          {bid[1]}
                        </Box>
                      </Fragment>
                    );
                  })}
              </Box>
              <Box width={'1px'} mx={1} bgcolor={grey[800]} />
              <Box width={'calc(50% - 1px)'} display="flex" flexWrap="wrap">
                <Box width={'100%'} textAlign="right" fontWeight={600} mb={1} color={'orders.sell'}>
                  ask
                </Box>
                {orderBook &&
                  orderbookBackground.ask &&
                  orderBook.ask &&
                  orderBook.ask.map((ask, key) => {
                    return (
                      <Fragment key={key}>
                        <Box
                          textAlign="left"
                          width={'50%'}
                          pb={1}
                          color={grey[200]}
                          style={{
                            background: `linear-gradient(to right, #491209 ${orderbookBackground.ask[key]}%, transparent 15%)`,
                            fontSize: 'small',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            paddingLeft: '3px',
                          }}
                          onClick={() => setSellPrice(scientificToDecimal(ask[0]))}
                        >
                          {ask[1]}
                        </Box>
                        <Box
                          textAlign="right"
                          width={'50%'}
                          color={'orders.sell'}
                          pb={1}
                          style={{ fontSize: 'small', paddingTop: '3px', paddingBottom: '3px' }}
                          onClick={() => setSellPrice(scientificToDecimal(ask[0]))}
                        >
                          {scientificToDecimal(ask[0])}
                        </Box>
                      </Fragment>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Box fontSize={'16px'} fontWeight={500} color={grey[200]} whiteSpace={'nowrap'}>
            Trading History
            <CategoryPicker
              onChange={handleHeadsTradingHistory}
              allCategories={labelsTradingHistory}
              categories={tradingHistoryHeads}
              small={true}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ServerSideTable
            exchange={exchange}
            pair={pair}
            style={{ minWidth: '100%', maxWidth: '100%' }}
            heads={tradingHistoryHeads}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Box fontSize={'16px'} fontWeight={500} color={grey[200]} whiteSpace={'nowrap'}>
            Open Orders ({currentOpenOrders.length})
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box mt={-4} mb={-1} width={'100%'} minHeight={'100px'}>
            <Table
              size={'small'}
              tableHead={['Rate', 'Amount', 'Time', 'Cancel order']}
              tableData={currentOpenOrders}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

const labelsTradingHistory = [
  {
    title: 'Rate',
    type: 'number',
    tooltip: 'Order fill rate',
  },
  {
    title: 'Pnl',
    type: 'number',
    tooltip: 'Order profit and loss',
  },
  {
    title: 'Amount',
    type: 'number',
    tooltip: 'Order amount',
  },
  {
    title: 'Amount (base)',
    type: 'number',
    tooltip: 'Order amount (base)',
  },
  {
    title: 'Time',
    type: 'number',
    tooltip: 'Order fill time',
  },
];